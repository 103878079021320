.feed {
  width: 70%;
  margin: auto;
}

.post {
  margin: 10px;
  background-color: gainsboro;
  padding: 15px;
  border-radius: 5px;
}

.post h3{
  margin: 0;
}

.header {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: gainsboro;
}
/*
.header .active {
  background-color: gray;
}*/

.header ul {
  margin: 0;
  padding: 0;
}

.header li {
  display: inline-block;
}

.header a {
  display: inline-block;
  padding: 5px 15px;
  border-right: 1px solid darkgray;
  background-color: gainsboro;
  margin: 0;
}

.header a {
  color: black;
}

.header a:visited{
  color: black;
}

.newPost {
  margin: 10px;
  background-color: gainsboro;
  border-radius: 5px;
  padding: 10px;
}

.newPost input, .newPost textarea, .newPost span {
  display: block;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  min-width: calc(100% - 20px);
  margin: 10px auto;
}

.newPost #title {
  font-weight: bold;
  font-size: 1.2em;
}

#newPostButton {
  display: block;
  background-color: gainsboro;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  text-align: center;
}

.right {
  display: block;
  height: 20px;
}

.right * {
  float: right;
}

#login {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  background-color: gainsboro;
  margin: 10px auto;
}

#login span {
  display: block;
}

#login input {
  width: calc(100% - 10px);
  margin: 5px 0;
}

.commentWriter {
  width: 100%;
}

.commandWriter button {
  width: 40px;
}

.commentWriter input {
  width: calc(100% - 51px)
}

.comment {
  border-bottom: 1px solid gray;
}

@media screen and (max-width: 900px){
  .feed {
    width: 100%;
    margin: 0;
  }
}